/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
let token = window.csrfTokenValue;
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = window.csrfTokenValue;
}

/**
 * Load/Import Plugins
 * If you don't see a plugin below it might be autloaded via webpack
 * Check webpack.mix.js for autoloaded plugins.
 */
require('jquery-match-height');


/**
 * Import Foundation Components
 * check ./foundation.js if you need to enable more
 */
import { Foundation } from './foundation';
Foundation.addToJquery(jQuery);


/**
 * Application script
 */

// Initialize Foundation
$(document).foundation();

// Additional Logic
$(function() {

    const $nav = $('.js--nav'),
          $body = $('body');

    const buttons = {
        $navOpenBtn : $('.js--top-bar-btn'),
        $navCloseBtn : $('.js--nav-btn')
    };

    const navActiveClass = 'nav--active',
          noScrollClass = 'noscroll';

    const handleNoScroll = function(e){
        e.preventDefault();
    }

    const $searchBorder = $('.js--site-search-input-border'),
        $siteSearchBtn = $('.js--site-search-submit'),
        $siteSearchInput = $('.js--site-search-input'),
        $siteSearchForm = $('#site-search'),
        $siteSearchMobileForm = $('#site-search-mobile');


    buttons.$navOpenBtn.on('click', function(e){
        e.preventDefault();
        $(window).scrollTop(0);
        $nav.addClass(navActiveClass);
        $body.addClass(noScrollClass);
        document.body.addEventListener('touchmove', handleNoScroll, { passive: false });
    });

    buttons.$navCloseBtn.on('click', function(e){
        e.preventDefault();
        $nav.removeClass(navActiveClass);
        $body.removeClass(noScrollClass);
        document.body.removeEventListener('touchmove', handleNoScroll, { passive: false });
    });

    // On click of a navigation item
    $nav.on('click', '.js--nav-link', function(e){
        e.preventDefault();

        const navId = $(this).data('navId'),
            $linkedNavContent = $(`.js--nav-content[data-nav-id="${navId}"]`),
            hasChildLinks = ($linkedNavContent.find('.nav__child-item').length > 0),
            isCurrentMenuItem = window.location.href === $(this).prop('href');

        // Navigate directly to the page if there's no child links.
        //if( !isCurrentMenuItem && !hasChildLinks ){
        if(!hasChildLinks){
            window.location = $(this).prop('href');
            return false;
        }

        // Update the active top-level navigation link
        $('.js--nav-link').removeClass('nav__link--active');
        $(this).addClass('nav__link--active');

        // Update the visible navigation content
        $('.js--nav-content').addClass('nav__content--hidden');
        $linkedNavContent.removeClass('nav__content--hidden');
    });

    // On click of the site search icon
    $('.js--site-search-btn').on('click', function(e){
        e.preventDefault();
        const currentBreakpoint = Foundation.MediaQuery.current;

        // Open the reveal modal if on mobile/tablet
        if( ['small','medium'].indexOf(currentBreakpoint) > -1 ){
            $('#searchModal').foundation('open');
        // Otherwise, focus the search input
        } else {
            $siteSearchInput.focus();
            $siteSearchBtn.addClass('site-search__submit--active');
        }
    });

    // Submit the site search form when the enter key is pressed
    $siteSearchInput.on('keyup', function(e){
        if( e.keyCode === 13 ) $(this).parents('form').eq(0).submit();
    // Show the input border and submit button when focused
    }).on('focusin', function(e){
        $searchBorder.addClass('site-search__border--active');
        $siteSearchBtn.addClass('site-search__submit--active');
    // On focusout, remove active classes from the border and submit button
    }).on('focusout', function(e){
        if( $(this).val().length > 0 ) return;
        $searchBorder.removeClass('site-search__border--active');
        $siteSearchBtn.removeClass('site-search__submit--active');
    });
});
